<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
             <button class="btn-search ml-5" type="button" @click="setTableData()">
                <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
      <!--div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.id') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.adminId" :placeholder="'아이디'" />

           </div>
         </div>
      </div-->
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <tr v-for="(item, idx) in applyList" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('company', item.regId)">{{ item.regId }}</button>
              </td>
              <td>
                <button v-if="item.calculateIdx" type="button" class="fc-id btn-link roboto">{{ item.calculateIdx }}</button>
                <template v-else> - </template><!-- @click="ptDetailOpen(item)" -->
              </td>
              <td>
                <span class="roboto">{{ numberWithCommas(item.preCreditAmt) }}</span>
              </td>
              <td>
                <span class="money-apply roboto">{{ numberWithCommas(item.creditAmt) }}</span>
              </td>
              <td>
                <span v-if="item.creditStatus === '1'" class="money-apply roboto">{{ numberWithCommas(item.realCreditAmt) }}</span>
                <span v-else>-</span>
              </td>
              <td>
                <span class="money-apply roboto" v-if="item.creditStatus === '1'">{{ numberWithCommas(Number(item.preCreditAmt) + Number(item.realCreditAmt)) }}</span>
                <span class="money-apply roboto" v-if="item.creditStatus !== '1'">-</span>
              </td>
              <td class="roboto">{{ Number(item.creditRate).toFixed(2) }}%</td>
              <td class="roboto">{{ numberWithCommas((Number(item.creditAmt) * (Number(item.creditRate) * 0.01)).toFixed(0)) }}</td>
              <td style="width: 15%">
                <span class="roboto">{{ item.regDate }}</span>
              </td>
              <td style="width: 15%">
                <span v-if="item.creditStatus === '1'" class="roboto">{{ item.updDate }}</span>
                <span v-if="item.creditStatus !== '1'">-</span>
              </td>
              <td>
                <div class="fx-center-all">
                  <span class="emptybet">{{ item.statusText }}</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="11">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="codeOpen" v-if="codeOpen">
        <h4>
          {{ $t('common.sub_point_popup') }}
          <a @click="ptDetailClose()" class="x"><i class="fa fa-times"></i></a>
        </h4>
        <ul class="head">
          <li>{{ $t('table.head.index') }}</li>
          <li>{{ $t('common.rateStd') }}</li>
          <li>{{ $t('common.rateGame') }}</li>
          <li>{{ $t('common.rateAp') }}</li>
          <li>{{ $t('table.head.betAmt') }}</li>
          <li>{{ $t('table.head.winAmt') }}</li>
          <li>{{ $t('table.head.creditDe') }}</li>
          <li>{{ $t('searchArea.paySub') }}</li>
          <li>{{ $t('table.head.date') }}</li>
        </ul>

        <template v-if="detailList.length">
          <template v-for="(item, idx) in detailList">
            <ul class="list">
              <li>{{ (detailPageInfo.page - 1) * 10 + (idx + 1) }}</li>
              <li>{{ item.baseRate }}</li>
              <li>{{ item.gameRate }}</li>
              <li>{{ item.creditSpRate }}</li>
              <li>{{ numberWithCommas(item.betAmt) }}</li>
              <li>{{ numberWithCommas(item.betWinAmt) }}</li>
              <li>{{ numberWithCommas(item.creditAmt) }}</li>
              <li>{{ numberWithCommas(item.creditSubPointAmt) }}</li>
              <li>{{ replaceDateT(item.regDate) }}</li>
            </ul>
          </template>
        </template>
        <template v-else>
          <ul class="list">
            <li>{{ $t('txt.noData') }}</li>
          </ul>
        </template>
        <pagination v-if="detailList.length !== 0" @goPage="getDetailList" :pageNum="detailPageInfo.page" :pageSize="detailPageInfo.count_per_list" :totalCount="detailPageInfo.tatal_list_count" />
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import { getSiteData } from '@/libs/auth-helper'
import { creditUpdateStatus } from '@/api/member.js'
import { creditMyList } from '@/api/company.js'
import { getDateStr, replaceDateT } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import UiCheck from '@/components/ui/UiCheckSet'
import { creditDetail } from '@/api/partner'
import Memo from '@/components/common/memo'

export default {
  name: 'CreditApplyList',
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    UiCheck,
    Memo
  },
  props: {
    status: {
      type: String,
      default: '0'
    }
  },
  watch: {
    $route: {
      async handler () {
        const status = this.$route.params.status
        console.log(status)
        this.setTableData(1, status)
      }
    }
  },
  data: function () {
    return {
      codeOpen: false,
      tableName: 'Basic',
      headInfo: {
        fstColumn: false,
        dataList: ['companyId', 'onecode', 'prePT', 'applyCredit', 'realCreditPT', 'postPT', 'creditRate', 'chargeAmt', 'applyDate', 'updDate', 'status']
      },
      reqData: {
        startDate: '',
        endDate: '',
        creditStatus: 0,
        page: 1,
        creditType: '1',
        count_per_list: '30',
        adminId: ''
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      pageInfo: {},
      applyList: [],
      siteInfo: {},
      checkList: [
        {
          val: '0',
          text: '요청'
        },
        {
          val: '1',
          text: '대기'
        },
        {
          val: '2',
          text: '완료'
        }
      ],
      detailList: [],
      detailPageInfo: {},
      detailInfo: {
        page: 1
      }
    }
  },
  methods: {
    replaceDateT,
    async ptDetailOpen (item) {
      this.detailInfo = item
      await this.getDetailList(1)
    },
    async ptDetailClose () {
      this.detailInfo = null
      this.detailList = []
      this.codeOpen = false
    },
    async getDetailList (page) {
      if (!page) {
        page = 1
        this.detailPageInfo.page = page
      }
      const params = {
        siteId: this.siteInfo.siteId,
        calculateIdx: this.detailInfo.calculateIdx,
        page
      }
      this.detailList = []

      await creditDetail(params).then(result => {
        console.log(result)
        if (result.resultCode === '0') {
          this.detailList = result.data.list
          this.detailPageInfo = result.data.pageInfo

          this.codeOpen = true
        }
      })
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getCreditApplyList (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      const data = this.reqData
      console.log(data)
      return await creditMyList(data)
    },
    async changeListType (evt) {
      const target = evt.target
      const chooseType = target.value
      this.reqData.creditStatus = chooseType
      this.setTableData(1)
    },
    async setTableData (pageNum, status) {
      if (status) {
        this.reqData.creditStatus = status
      } else {
        this.reqData.creditStatus = 0
      }

      const res = await this.getCreditApplyList(pageNum)
      const applyList = res.data.list
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      this.applyList = this.dataConvertor(applyList)
      console.log(this.applyList)
    },
    creditPayment (dataObj) {
      const _creditAmt = prompt('알 지급 금액', dataObj.creditAmt)
      const trigger = {
        msg: '',
        prog: false
      }
      if (_creditAmt) {
        if (isNaN(_creditAmt)) {
          trigger.msg = '숫자만 입력해주세요.'
        } else if (_creditAmt <= 0) {
          trigger.msg = '지급 최소 금액은 0 이상 입니다. 다시 입력해주세요.'
        } else if (_creditAmt == null || _creditAmt == undefined) {
          trigger.msg = ''
        } else {
          trigger.prog = confirm(`${this.numberWithCommas(_creditAmt)}을 지급합니다.`)
          if (trigger.prog) {
            trigger.msg = _creditAmt
          } else {
            trigger.msg = '알 지급 취소'
          }
        }
      } else {
        trigger.msg = ''
        trigger.prog = false
      }

      return trigger
    },
    async statusChange (idx, creditAmt, type, status) {
      const reqData = {
        creditIdx: idx,
        creditType: type,
        creditStatus: status,
        creditAmt
      }
      switch (status) {
        case '0':
          reqData.creditStatus = '2'
          break
        case '2':
          reqData.creditStatus = '1'
          const preCheck = this.creditPayment(reqData)
          if (preCheck.prog) {
            reqData.creditAmt = preCheck.msg
          } else {
            if (preCheck.msg) {
              alert(preCheck.msg)
            }
            return
          }
          break
        case '1':
          (reqData.creditStatus = '1'), (reqData.creditType = '-1')
          reqData.creditAmt = '0'
          break
      }
      const res = await creditUpdateStatus(reqData)
      this.setTableData()
      if (res.resultCode === '0') {
        if (reqData.creditStatus === '1') {
          alert('알 지급 완료')
        } else {
          alert('알 상태 변경 완료')
        }
      } else {
        if (reqData.creditStatus === '1') {
          alert('알 지급에 실패 했습니다. 다시 시도해주세요.')
        } else {
          alert('알 상태 변경에 실패 했습니다. 다시 시도해주세요.')
        }
      }
    },
    dataConvertor (dataList) {
      const _dataList = dataList
      if (!dataList.length || dataList.length === 0) {
        return _dataList
      }
      _dataList.forEach(items => {
        const creditStatus = items.creditStatus
        let statusText = ''
        // - 0:요청, 1:완료, 2:대기 , -1"거부
        if (creditStatus === '0') {
          statusText = '지급신청'
          items.statusClass = 'apply'
        } else if (creditStatus === '1') {
          statusText = '지급완료'
          items.statusClass = 'approve'
        } else if (creditStatus === '2') {
          statusText = '입금대기'
          items.statusClass = 'wait'
        } else if (creditStatus === '-1') {
          statusText = '지급거절'
          items.statusClass = 'refuse'
        }
        if (!items.realCreditAmt) {
          items.realCreditAmt = '-'
        }
        items.statusText = statusText
        items.regDate = items.regDate.replace('T', ' ')
        items.updDate = items.updDate.replace('T', ' ')
        for (const item in items) {
          if (items[item] === '') {
            items[item] = '-'
          }
        }
      })
      return _dataList
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.reqData.adminId = this.siteInfo.siteId
    this.setStartDate()
    this.setEndDate()
    this.setTableData()
  }
}
</script>

<style scoped>
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
.codeOpen {
  min-width: 1000px;
  width: 80%;
  max-height: 850px;
  background: #fff;
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 30px 40px;
  border: 1px solid #353535;
  overflow: auto;
  z-index: 100000;
}
.codeOpen > h4 {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.codeOpen .x {
  font-size: 30px;
  cursor: pointer;
}
.codeOpen ul {
  display: flex;
  font-size: 14px;
}
.codeOpen li {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.codeOpen .head li {
  background: #353535;
  color: #fff;
}
.codeOpen .list li {
  border-bottom: 1px solid #353535;
}
</style>
